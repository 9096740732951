<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="pages" v-loading="loading">
        <table-height-auto>
            <template slot="before">
                <el-form :inline="true" class="demo-form-inline">
                    <div>
                        <el-form-item label="用户手机号">
                            <el-input
                                v-model="searchData.publishUserPhone"
                                clearable
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select
                                v-model="searchData.status"
                                placeholder="请选择"
                                style="width: 160px"
                            >
                                <el-option
                                    v-for="item in serviceType"
                                    :disabled="item.disabled"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="土地SKU">
                            <el-input
                                v-model="searchData.skuName"
                                clearable
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="search"
                                >查询</el-button
                            >
                            <el-button type="primary" @click="reset"
                                >重置</el-button
                            >
                        </el-form-item>
                    </div>
                    <div>
                        <el-button type="primary" @click="rowDetails({})" >新增记录</el-button>
                    </div>

                </el-form>
            </template>

            <template v-slot:table="row">
                <el-table
                    v-if="row.height"
                    :height="row.heightText"
                    :data="tableData"
                    style="width: 100%"
                >
                    <el-table-column prop="date" label="图片/视频" width="180">
                        <template #default="scope">
                            <el-image
                                class="avatar"
                                @click="showBox(scope.row.pictures.pics)"
                                fit="contain"
                                :src="scope.row.pictures.pics[0]"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="title"
                        label="视频标题"
                    ></el-table-column>
                    <el-table-column
                        prop="description"
                        label="视频说明"
                        show-overflow-tooltip
                        width="180"
                    />
                    <el-table-column
                        prop="landName"
                        label="土地标题"
                    ></el-table-column>
                    <el-table-column
                        prop="skuName"
                        label="土地SKU"
                        width="100"
                    />
                    <el-table-column prop="status" label="用户信息" width="120">
                        <template #default="scope">
                            <el-image
                                class="avatar"
                                fit="contain"
                                :src="
                                    scope.row.publishUserLogo
                                        ? scope.row.publishUserLogo
                                        : defaultAvatarUrl
                                "
                            />
                            <div>{{ scope.row.publishUserPhone }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态" width="80">
                        <template #default="scope">
                            <el-button
                                @click="switchClick(scope.row)"
                                type="text"
                                >{{
                                    scope.row.status == 1 ? "开启" : "关闭"
                                }}</el-button
                            >
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderNum" label="排序" />
                    <el-table-column prop="createTime" label="添加时间" />
                    <el-table-column prop="date" label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="primary"
                                v-if="scope.row.status === 0"
                                @click="rowDetails(scope.row)"
                                >编辑</el-button
                            >
                            <el-button
                                size="mini"
                                type="primary"
                                @click="addTypes(scope.row)"
                                >查看</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </template>

            <template slot="after">
                <div class="paging-row">
                    <el-pagination
                        v-if="totalListNum"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalListNum"
                    >
                    </el-pagination>
                </div>
            </template>
        </table-height-auto>
        <el-dialog
            title="查看"
            append-to-body
            style="z-index: 5001;"
            class="my-dialog"
            :visible.sync="isVisible"
            width="800px"
        >
            <el-carousel
                v-if="isVisible"
                height="430px"
                :autoplay="false"
                trigger="click"
                :initial-index="initialIndex"
            >
                <el-image
                    v-if="showData.type == 1"
                    :src="showData.img"
                    fit="contain"
                    style="width: 760px;height: 430px;"
                />
                <video
                    v-else-if="showData.type == 2"
                    :src="showData.video"
                    controls="controls"
                    style="width: 760px;height: 430px;"
                >
                    您的浏览器不支持视频播放
                </video>
                <el-empty
                    v-else
                    :image-size="180"
                    description="数据格式有误！"
                />
            </el-carousel>
            <template #footer>
                <el-button type="primary" @click="isVisible = false"
                    >确定</el-button
                >
            </template>
        </el-dialog>
        <record
            v-if="isItemVisble"
            :drawerVisible="ItemVisble"
            :currentRow="currentRow"
            @close="closeItem"
        ></record>
        <record-detail
            :drawerVisible="typeVisble"
            v-if="isTypeVisble"
            :currentData="currentData"
            @close="closeTypeVisble"
        ></record-detail>
    </div>
</template>

<!-- const URL = {
		list: 'back/suppFarm/getFarmRecordPage',
		getFarmRecordDetail :'/back/suppFarm/getFarmRecordDetail',
		switchFarmRecordStatus :'/back/suppFarm/switchFarmRecordStatus',
	} -->

<script>
const newURL = {
    getFarmRecordDetail: "/farm-api/web/farm/record/getById",
    changeStatus: "/farm-api/web/farm/record/enable"
};
import { disposeSearchData } from "../../../assets/js/utils";
import recordDetail from "./components/recordDetail.vue";
import record from "./components/record.vue";
import defaultAvatarUrl from "@/assets/defaultAvatarUrl.png";

import { URL } from "../../../config";

export default {
    name: "land",
    components: {
        record,
        recordDetail
    },
    data() {
        return {
            initialIndex: 0,
            typeVisble: false,
            isVisible: false,
            isTypeVisble: false,
            isItemVisble: true,
            ItemVisble: false,
            currentRow: {},
            currentData: {},
            loading: false,
            searchTimeType: [
                { id: "", name: "全部" },
                { id: "createTime", name: "下单时间" },
                { id: "payTime", name: "支付时间" },
                { id: "sendTime", name: "发货时间" },
                { id: "receiveTime", name: "收货时间" },
                { id: "completeTime", name: "完结时间" }
            ],
            searchData: {
                publishUserPhone: "",
                status: null,
                skuName: ""
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            tableData: [],
            serviceType: [
                { id: "1", name: "开启" },
                { id: "2", name: "关闭" }
            ],
            currentPage: 1,
            totalListNum: 0,
            pageSize: 10,
            showData: {},
            defaultAvatarUrl
        };
    },
    created() {
        this.getList();
    },
    methods: {
        switchClick(row) {
            let params = {
                id: row.id
            };
            this.$confirm(
                row.status === 1 ? "是否关闭农场记录" : "是否开启农场记录",
                "温馨提示",
                {
                    type: "warning"
                }
            )
                .then(() => {
                    this.axios
                        .put(newURL.changeStatus, {
                            id: row.id,
                            status: row.status === 1 ? 0 : 1
                        })
                        .then(res => {
                            this.$message.success("操作成功");
                            this.getList();
                        });
                })
                .catch(() => {});
        },
        showBox(row) {
            this.showData = row;
            this.isVisible = true;
        },
        reset() {
            (this.searchData = {
                publishUserPhone: "",
                skuName: "",
                status: ""
            }),
                this.getList();
        },
        closeItem() {
            this.ItemVisble = false;
            this.$nextTick(() => {
                this.isItemVisble = false;
            });
            this.currentRow = {};
            this.getList();
        },

        closeTypeVisble() {
            this.typeVisble = false;
            this.$nextTick(() => {
                this.isTypeVisble = false;
            });
        },

        addTypes(row) {
            let data = {
                id: row.id
            };
            this.axios
                .get(`${newURL.getFarmRecordDetail}/${row.id}`)
                .then(res => {
                    this.currentData = res.data;
                    this.isTypeVisble = true;
                    this.$nextTick(() => {
                        this.typeVisble = true;
                    });
                });
        },

        rowDetails(row, title) {
            let id = row.id;
            this.isItemVisble = true;
            if (!id) {
                this.currentRow = row;
                this.ItemVisble = true;
                return;
            }
            this.axios
                .get(`${URL.farm.farmrecordgetById}/${row.id}`)
                .then(res => {
                    this.currentRow = res.data;
                    this.ItemVisble = true;
                });
        },

        closed() {
            this.$emit("closed", ...arguments);
        },

        jointSearchData() {
            let data = {
                current: this.currentPage,
                size: this.pageSize
            };
            data = Object.assign(data, this.searchData);
            disposeSearchData(data);
            return data;
        },
        search() {
            this.currentPage = 1;
            this.getList();
        },
        getList() {
            return new Promise((resolve, reject) => {
                let data = this.jointSearchData();
                let params = {
                    pageNo: this.currentPage,
                    pageSize: this.pageSize,
                    ...this.searchData
                };
                this.loading = true;
                this.axios
                    .post(URL.farm.farmrecordlist, params)
                    .then(res => {
                        this.totalListNum = parseInt(res.data.total);
                        this.tableData = res.data.records;
                    })
                    .catch(res => {
                        this.tableData = [];
                    })
                    .finally(res => {
                        this.loading = false;
                    });
            });
        },
        handleSizeChange(val, type) {
            this.pageSize = val;
            this.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(val, type) {
            this.currentPage = val;
            this.getList();
        }
    }
};
</script>

<style lang="less">
.form-item {
    .el-input {
        max-width: 300px;
    }
}
.avatar {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
</style>
