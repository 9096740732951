<template>
    <el-drawer
        size="800px"
        :title="formData.name"
        :visible.sync="drawerVisible"
        :direction="'rtl'"
        :wrapperClosable="false"
        :before-close="close"
        custom-class="publicityItem"
    >
        <el-form
            inline
            :model="formData"
            class="formData"
            ref="formData"
            label-width="100px"
            v-loading="loading"
        >
            <el-form-item
                label="视频标题："
                prop="title"
                :rules="formRules.required"
            >
                <el-input
                    v-model="formData.title"
                    style="width:600px"
                    maxlength="30"
                    placeholder="请输入标题"
                    show-word-limit
                />
            </el-form-item>
            <el-form-item
                label="视频说明："
                prop="description"
                :rules="formRules.required"
            >
                <el-input
                    type="textarea"
                    :rows="2"
                    style="width:600px"
                    resize="none"
                    placeholder="请输入土地说明"
                    v-model="formData.description"
                    maxlength="100"
                    show-word-limit
                ></el-input>
            </el-form-item>
            <el-form-item label="记录详情：" :rules="formRules.required">
                <span>图片</span>
                <upload-images
                    :file-list.sync="formData.pictures"
                    width="100px"
                    :canvas="editGoods"
                    :limit="8"
                ></upload-images>
                <span>视频</span>
                <UploadVodVideo
                    class="videoBox"
                    :filesSize="10240"
                    width="100px"
                    :file="formData.video ? formData.video.video : ''"
                    @update:file="changeVodVideoFiles"
                />
                <span v-if="formData.video">视频封面图</span>
                <VideoLand
                    v-if="isVideoSnapshot"
                    :datum="formData.video"
                    @update:file="changeFilesImg"
                />
                <div
                    @click="isVideoSnapshot = true"
                    v-if="formData.video"
                    style="width: 190px;height: 108px;line-height:108px;text-align:center;border: 1px dashed #e9e9e9;cursor: pointer;margin-bottom:20px"
                >
                    <img
                        v-if="formData.cover"
                        :src="formData.cover"
                        style="width: 190px;height: 108px;border: 1px solid #e9e9e9;cursor: pointer;"
                        alt=""
                    />
                    <div class="" v-else>
                        <i
                            class="el-icon-plus avatar-uploader-icon"
                            style="font-size:28px;"
                        ></i>
                    </div>
                </div>
            </el-form-item>
            <div>
                <el-form-item
                    label="选择土地"
                    prop="landId"
                    :rules="formRules.requiredChange"
                >
                    <el-select
                        @change="tabClick"
                        v-model="formData.landId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in landList"
                            :key="item.id"
                            :label="item.landDistrictName + item.name"
                            :value="item.landDistrictId"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div>
                <el-form-item
                    label="土地SKU"
                    prop="skuName"
                    :rules="formRules.requiredChange"
                >
                    <el-select
                        @change="resetSKu"
                        clearable
                        v-model="formData.skuName"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in landskuList"
                            :key="item.name"
                            :label="item.name"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div>
                <el-form-item
                    label="排序"
                    :rules="formRules.required"
                    prop="orderNum"
                >
                    <el-input-number
                        v-model="formData.orderNum"
                        :precision="0"
                        placeholder="请输入排序"
                        :step="1"
                        :min="1"
                        :max="99999"
                    ></el-input-number>
                </el-form-item>
            </div>
            <el-form-item style="overflow: hidden;">
                <div style="margin-bottom: 60px;float: right;">
                    <el-button type="primary" @click="submit(formData)"
                        >确认</el-button
                    >
                    <el-button plain @click="close">取消</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-drawer>
</template>
<script>
import VideoLand from "@/components/supplier/editPostage/video-land.vue";
import { editGoods } from "@/config/imageSize";
import { policyDetails } from "@/config/imageSize";
import uploadImages from "@/components/public/upload/uploadImages";
import newUploadImages from "@public/upload/newUploadImages";
import UploadVodVideo from "@/components/public/upload/uploadVodVideo.vue";
import UploadVideo from "@public/upload/uploadVideo";
import { miniToolbarOptionsVideo } from "@/assets/js/utils";
import * as formRules from "@/assets/js/formRules";

const API = {
    landskuList: "/farm-api/web/farm/record/getLabelByLandDistrictId",
    newlandList: "/farm-api/web/farm/land/page",
    saveFarmRecord: "/back/suppFarm/saveFarmRecord",
    getSeedsList: "/back/suppFarm/getSeedsList",
    getFarmServiceTypeList: "/back/suppFarm/getFarmServiceTypeList",
    list: "/back/suppFarm/getFarmLandList",
    getFarmLandDivisionList: "/back/suppFarm/getFarmLandDivisionList"
};
import { URL } from "../../../../config/url.supplier";
export default {
    name: "publicityItem",
    props: {
        drawerVisible: {
            type: Boolean,
            default: false
        },
        currentRow: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        uploadImages,
        UploadVodVideo,
        newUploadImages,
        UploadVideo,
        VideoLand
    },
    data() {
        return {
            landList: [],
            landskuList: [],
            tableData: [],
            skuList: [],
            options: [],
            seedsList: [],
            //serviceTypeList: [],
            isVideoSnapshot: false,
            checked: true,
            iviewUpVideo: true,
            isTag: false,

            editGoods,
            slideList: [],
            loading: false,
            // formData:{},
            formData: {
                name: "",
                description: "",
                status: 1, //开启状态
                orderNum: "",
                // divisionList: [
                // 	{ name: 'sku',id: ''},
                // ],
                rentCycles: true,
                serviceTypeIds: [],
                seedIds: [],
                slideList: [],
                contactPhone: "",
                detail: "",
                video: {},
                pictures: []
            },
            formRules,
            editorOption: {
                placeholder: "请在这里输入",
                theme: "snow",
                modules: {
                    toolbar: {
                        container: miniToolbarOptionsVideo, // 工具栏
                        handlers: {
                            image: function(value) {
                                if (value) {
                                    document
                                        .querySelector("#iviewUp input")
                                        .click();
                                } else {
                                    this.quill.format("image", false);
                                }
                            },
                            video: function(value) {
                                if (value) {
                                    document
                                        .querySelector("#iviewUpVideo input")
                                        .click();
                                } else {
                                    this.quill.format("video", false);
                                }
                            }
                        }
                    }
                },
                clipboard: {
                    matchers: [[Node.ELEMENT_NODE, this.handleCustomMatcher]]
                }
            },
            policyDetails
        };
    },
    watch: {
        currentRow: {
            handler(val, oldval) {
                if (val.id) {
                    this.formData = {
                        title: val.title,
                        description: val.description,
                        orderNum: val.orderNum,
                        landId: val.landId,
                        landName: val.landName,
                        blockNo: val.blockNo,
                        //landDivisionId: val.landDivisionId,
                        status: val.status,
                        id: val.id,
                        skuName: val.skuName
                    };
                    this.formData.pictures = val.pictures.pics;
                    this.formData.cover = val.video?.cover;
                    this.formData.video = {
                        video: val.video?.video
                    };
                    this.getlandskuList(val.landId);
                    this.getLandList().then(() => {
                        let role = false
                        for (let i = 0; i < this.landList.length; i++) {
                            if (this.formData.landId === this.landList[i].landDistrictId) {
                                role = true;
                            }
                        }
                        if (!role) {
                            this.formData.landId = this.formData.landName;
                        }
                        console.log(this.formData,123);
                    })
                } else {
                    this.formData = {
                        video: {},
                        cover: "",
                        title: "",
                        description: "",
                        orderNum: "",
                        landId: "",
                        blockNo: "",
                        status: 1
                        //landDivisionId: '',
                    };
                }
            },
            deep: true
        }
    },
    created() {
        this.getLandList();
        this.getSeedsList();
        //this.getFarmServiceTypeListFun();
        this.getList();
        //this.getFarmLandDivisionList();
    },
    methods: {
        async getlandskuList(val) {
            await this.getidLandList(val);
        },
        resetSKu(val) {
            this.$set(this.formData, "blockNo", val.value);
            this.$set(this.formData, "skuName", val.name);
        },
        formatNumber(num) {
            let str = String(num);
            if (str.length < 4) {
                while (str.length < 4) {
                    str = "0" + str;
                }
            }
            return str;
        },
        //土地sku
        // getlandskuList(val){
        //     this.axios.put(API.landskuList+'?landDistrictId='+val).then(res=>{
        //         this.landskuList = res.data
        //     })
        // },
        //编辑时根据id获取土地sku
        getidLandList(val) {
            this.axios
                .post(API.newlandList, { pageSize: 10000, status: 1 })
                .then(res => {
                    let landListnew = res.data.records;
                    const landItem = landListnew.find(res => {
                        return res.landDistrictId === val;
                    });
                    this.landskuList = [];
                    for (
                        let i = landItem.blockNoStart;
                        i <= landItem.blockNoEnd;
                        i++
                    ) {
                        this.landskuList.push({
                            value: i,
                            name:
                                landItem.landDistrictName + this.formatNumber(i)
                        });
                    }
                });
        },
        async getLandList() {
            await this.axios
                .post(API.newlandList, { pageSize: 10000, status: 1 })
                .then(res => {
                    this.landList = res.data.records;
                });
        },
        // skuChange(val){
        //     console.log(val)
        //     this.$set(this.formData,'blockNo',val.id)
        // },
        getFarmLandDivisionList(id) {
            let data = {
                landId: id ? id : ""
            };
            this.axios
                .get(API.getFarmLandDivisionList, { params: data })
                .then(res => {
                    this.skuList = res.data;
                })
                .catch(res => {
                    this.skuList = [];
                });
        },
        tabClick(val) {
            // this.formData.landId = val;

            const landItem = this.landList.find(res => {
                return res.landDistrictId == val;
            });
            this.$set(this.formData, "skuName", "");

            this.skuList = [];
            this.landskuList = [];
            for (let i = landItem.blockNoStart; i <= landItem.blockNoEnd; i++) {
                this.landskuList.push({
                    value: i,
                    name: landItem.landDistrictName + this.formatNumber(i)
                });
            }

            //this.$set(this.formData, 'blockNo', null)
            //this.getlandskuList(val)
            //this.getFarmLandDivisionList(val)
        },
        getList() {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(API.list)
                    .then(res => {
                        this.tableData = res.data;
                    })
                    .catch(res => {
                        this.tableData = [];
                    });
            });
        },
        getSeedsList() {
            this.axios.get(API.getSeedsList).then(res => {
                this.$nextTick(() => {
                    this.seedsList = res.data;
                });
            });
        },
        // getFarmServiceTypeListFun() {
        //     this.axios.get(API.getFarmServiceTypeList).then(res => {
        //         this.serviceTypeList = res.data;
        //     });
        // },
        subTag() {
            if (this.formData.tag == "") return this.$message.error("请输入SKU名称");
            this.formData.divisionList.push({
                name: this.formData.tag,
                id: ""
            });
            this.formData.tag = "";
            this.isTag = false;
        },
        changeFilesImg(img) {
            if (img.indexOf("http") != "-1") {
                this.formData.cover = img;
            }
            this.isVideoSnapshot = false;
        },
        handleClose(index) {
            this.formData.divisionList.splice(
                this.formData.divisionList[index],
                1
            );
        },
        changeVodVideoFiles(videoFile) {
            // this.formData.video = videoFile;
            // this.$nextTick(() => {
            //     this.formData.video = videoFile;
            // });

            this.$set(this.formData,"video", videoFile)
        },
        uploadSuccess(url, key) {
            if (key === "banner") {
                this.row.banner = url;
            } else if (key === "mainIcon") {
                this.cultureForm.mainIcon = url;
            } else if (key === "richText" && url) {
                let quill = this.$refs.myQuillEditor.quill;
                let length = quill.getSelection().index; // 获取光标所在位置
                quill.insertEmbed(length, "image", url);
                quill.setSelection(length + 1); // 调整光标到最后
            }
        },
        uploadSuccessVideo({ video }) {
            let quill = this.$refs.myQuillEditor.quill;
            let length = quill.getSelection().index;
            quill.insertEmbed(length, "video", video);
            quill.setSelection(length + 1);
            this.iviewUpVideo = false;
            this.$nextTick(() => {
                this.iviewUpVideo = true;
            });
        },
        handleCustomMatcher(node, Delta) {
            const ops = [];
            this.$message.closeAll();
            Delta.ops.forEach(op => {
                if (op.insert && typeof op.insert === "string") {
                    ops.push({
                        insert: op.insert,
                        attributes: op.attributes
                    });
                } else {
                    if (
                        op.insert &&
                        op.insert.image &&
                        op.insert.image.indexOf("base64") > -1
                    ) {
                        this.$message.error("禁止粘贴base64格式图片");
                    } else {
                        ops.push({
                            insert: op.insert,
                            attributes: op.attributes
                        });
                    }
                }
            });
            Delta.ops = ops;
            return Delta;
        },
        submit(data) {
            // if (this.formData.video.video) {
            //     if (!this.formData.cover)
            //         return this.$message.error("请上传视频封面图");
            // }
            if (
                // !this.formData.video.video &&
                this.formData.pictures.length == 0
            ) {
                return this.$message.error("请至少上传一张图片、视频可以选填");
            }

            // let mediaList = []
            // if (this.formData.slideList.length > 0) {
            // 	for(const v of this.formData.slideList.values()) {
            // 		let obj = {
            // 			img: v,
            // 			videoCover: '',
            // 			video: '',
            // 			videoFileId: '',
            // 			type: 1
            // 		}
            // 		mediaList.push(obj)
            // 	}
            // }

            // if (this.formData.video.video) {
            // 	mediaList.push({
            // 		img: '', videoCover: this.formData.videoCover,
            // 		video: this.formData.video.video,
            // 		videoFileId: this.formData.video.fileId,
            // 		type: 2
            // 	})
            // }

            this.$refs.formData.validate(valid => {
                if (!valid) {
                    return;
                }
                // if (!emptyRichText(this.formData.content)){
                // this.$message.error("富文本内容前不能有空格")
                // return}
                if (this.formData.landId === this.formData.landName) {
                    this.$message.warning("土地已下架，请重新选择！");
                    return;
                }
                let params = {
                    title: this.formData.title,
                    description: this.formData.description,
                    orderNum: this.formData.orderNum,
                    landId: this.formData.landId,
                    skuName: this.formData.skuName,
                    blockNo: this.formData.blockNo,
                    status: this.formData.status
                    // mediaList: mediaList,
                };

                params.pictures = {
                    pics: [...this.formData.pictures]
                };

                params.video = {
                    cover: this.formData.cover,
                    video: this.formData.video ? this.formData.video.video : ""
                };

                if (this.formData.id) params.id = this.formData.id;

                this.loading = true;
                let url =
                    JSON.stringify(this.currentRow) == "{}"
                        ? URL.farm.farmrecordsave
                        : URL.farm.farmrecordupdate;
                this.axios[
                    JSON.stringify(this.currentRow) == "{}" ? "post" : "put"
                ](url, params)
                    .then(res => {
                        this.close();
                        this.$message.success("保存成功");
                    })
                    .finally(res => {
                        this.loading = false;
                    });
            });
        },
        close(e) {
            this.$emit("close", e ?? "");
        }
    }
};
</script>
<style lang="less" scoped>
.formData {
    padding: 0 20px;
    h4 {
        text-align: center;
    }
    .quillBox {
        width: 600px;
        height: 500px;
        /deep/.ql-formats {
            line-height: 0;
        }
        /deep/.ql-toolbar {
            line-height: 0;
        }
        /deep/.viewOnly {
            height: 0px;
            overflow: hidden;
            border-top: 0;
            padding: 0px;
        }
    }
    .upload-item-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .marginL10 {
        margin-left: 10px;
    }
    h4 {
        margin: 15px 0;
    }
}
.avatar-uploader-quill {
    display: none;
}
/deep/ .publicityItem {
    .el-drawer__header {
        margin-bottom: 10px;
    }
    .el-form-item {
        margin-bottom: 18px;
    }
}

.videoBox {
    position: relative;
    /deep/ .el-upload--picture-card {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    // /deep/ .avatar-uploader-icon {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     margin-left: -20px;
    //     margin-top: -14px;
    // }
}
/deep/ .el-tag {
    margin-right: 10px;
}

/deep/ .el-drawer__body {
    height: 100%;
    .formData {
        height: 100%;
        overflow: scroll;
    }
}

// /deep/ .video-snapshot {
/deep/ .el-dialog__wrapper {
    z-index: 2010 !important;
}
// /deep/  .v-modal {
// 	display: none !important;
// }
.videoTips {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000;
    z-index: 2009 !important;
}
// }
</style>
