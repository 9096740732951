<template>
    <div>
        <el-dialog
            size="1000px"
            direction="rtl"
            title="查看详情"
            :before-close="close"
            :visible.sync="drawerVisible"
            :destroy-on-close="true"
            custom-class="box-drawer"
        >
            <div class="content">
                <p>视频标题： {{ currentData.title }}</p>
                <p>视频说明： {{ currentData.description }}</p>

                <!--                <p>-->
                <!--                    记录详情：-->
                <!--                    <el-row>-->
                <!--                        <el-col v-if="!currentData.video">-->
                <!--                            暂无数据-->
                <!--                            &lt;!&ndash;                            <el-empty :image-size="80" />&ndash;&gt;-->
                <!--                        </el-col>-->
                <!--                        <el-col-->
                <!--                            v-else-->
                <!--                            :span="6"-->
                <!--                            style="cursor: pointer;"-->
                <!--                            v-for="(item, index) in currentData.video"-->
                <!--                            :key="index"-->
                <!--                        >-->
                <!--                            <el-image-->
                <!--                                @click="previewItem(item, index)"-->
                <!--                                v-if="item.type == 1"-->
                <!--                                style="width: 100px; height: 100px;"-->
                <!--                                fit="contain"-->
                <!--                                :src="item.img"-->
                <!--                            />-->
                <!--                            <div-->
                <!--                                @click="previewItem(item, index)"-->
                <!--                                class="videoMiniBox"-->
                <!--                                v-else-if="item.type == 2"-->
                <!--                            >-->
                <!--                                <video-->
                <!--                                    class="videoMini"-->
                <!--                                    :src="item.video"-->
                <!--                                    controls="controls"-->
                <!--                                    style="width: 100px;height: 100px;"-->
                <!--                                >-->
                <!--                                    您的浏览器不支持视频播放-->
                <!--                                </video>-->
                <!--                                <el-icon><VideoPlay /></el-icon>-->
                <!--                            </div>-->
                <!--                            <span v-else> 数据错误！ </span>-->
                <!--                        </el-col>-->
                <!--                    </el-row>-->
                <!--                </p>-->
                <p>土地SKU: {{ currentData.skuName }}</p>
                <p>
                    用户信息：<img
                        class="img"
                        :src="
                            currentData.avatarUrl
                                ? currentData.avatarUrl
                                : defaultAvatarUrl
                        "
                        alt=""
                    />{{ currentData.phone }}
                </p>
                <p>排序： {{ currentData.orderNum }}</p>
                <p>添加时间： {{ currentData.createTime }}</p>
            </div>
        </el-dialog>
        <el-dialog
            title="查看"
            append-to-body
            style="z-index: 5001;"
            class="my-dialog"
            :visible.sync="isVisible"
            width="800px"
        >
            <el-carousel
                v-if="isVisible"
                height="430px"
                :autoplay="false"
                trigger="click"
                :initial-index="initialIndex"
            >
                <el-carousel-item
                    v-for="item in currentData.mediaList"
                    :key="item"
                >
                    <el-image
                        v-if="item.type == 1"
                        :src="item.img"
                        fit="contain"
                        style="width: 760px;height: 430px;"
                    />
                    <video
                        v-else-if="item.type == 2"
                        :src="item.video"
                        controls="controls"
                        style="width: 760px;height: 430px;"
                    >
                        您的浏览器不支持视频播放
                    </video>
                    <el-empty
                        v-else
                        :image-size="180"
                        description="数据格式有误！"
                    />
                </el-carousel-item>
            </el-carousel>
            <template #footer>
                <el-button type="primary" @click="isVisible = false"
                    >确定</el-button
                >
            </template>
        </el-dialog>
        <!-- 记录预览 -->
    </div>
</template>
<script>
import defaultAvatarUrl from "@/assets/defaultAvatarUrl.png";
export default {
    name: "recordDetail",
    components: {},
    props: {
        drawerVisible: {
            type: Boolean,
            default: false
        },
        currentData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            list: [
                {
                    url:
                        "http://imgs.cunzhiliao.com/tmp/images/2023/05/04/20230504102803_n9LLzt2a.jpeg",
                    type: "image"
                },
                {
                    url:
                        "http://imgs.cunzhiliao.com/tmp/images/2023/05/04/20230504102839_IKwavO9C.jpeg",
                    type: "image"
                },
                {
                    url:
                        "http://imgs.cunzhiliao.com/tmp/images/2023/05/04/20230504102902_h1YTFBtO.jpeg",
                    type: "image"
                },
                {
                    url:
                        "http://1306840708.vod2.myqcloud.com/646dc027vodcq1306840708/5a7eb4d5243791581939924013/62CKvTD2HXwA.mov",
                    type: "video"
                }
            ],
            initialIndex: 0,
            isVisible: false,
            defaultAvatarUrl
        };
    },
    computed: {},
    mounted() {
        console.log(this.currentData, "currentData");
    },
    methods: {
        previewItem(item, index) {
            if (!item.type) {
                this.$message.warning("数据格式有误，无法预览！");
                return;
            }
            this.initialIndex = index;
            this.isVisible = true;
        },
        close(e) {
            this.$emit("close", e ?? "");
        }
    }
};
</script>
<style lang="less" scoped>
.box-drawer {
    .content {
        margin: 20px;
        p {
            line-height: 23px;
            padding-left: 20px;
            padding-right: 10px;
            font-size: 14px;
            color: #606266;
        }
        .img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 20px;
        }
        .el-row {
            padding: 10px;
            margin: 10px 0;
            background: #d7d7d7;
            border-radius: 7px;

            .el-col {
                p {
                    line-height: 25px;
                }
            }

            .gridMember {
                display: flex;
                justify-content: space-between;

                .inline-icon {
                    margin-left: 7px;
                    text-align: end;

                    img {
                        width: 20px;
                        height: 20px;
                        vertical-align: middle;
                    }
                }
            }

            .videoMiniBox {
                position: relative;

                .videoMini {
                    background: black;
                    filter: brightness(0.5);

                    &::-webkit-media-controls-enclosure {
                        display: none;
                    }
                }

                i {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100px;
                    height: 100px;
                    color: white;
                    font-size: 30px;
                    font-weight: bold;
                }
            }

            .previewBox {
                width: 760px;
                height: 430px;
            }
        }
    }
}
</style>
